import { combineReducers, applyMiddleware, createStore } from 'redux';
import thunkMiddleWare from 'redux-thunk';
import multi from 'redux-multi';
import commonReducer from './commonReducer';

let reducers = combineReducers({
    common: commonReducer
})

let store = createStore(reducers, applyMiddleware(thunkMiddleWare, multi))

window.store = store

export default store