import { checkoutApi } from "../Api/api"

const SET_IS_FETCHING = "SET_IS_FETCHING"
const SET_PAYMENT_URL = "SET_PAYMENT_URL"

let initialState = {
    isFetching: false,
    paymentUrl: null
}

const commonReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_IS_FETCHING: {
            return { ...state, isFetching: action.isFetching }
        }
        case SET_PAYMENT_URL: {
            return { ...state, paymentUrl: action.paymentUrl }
        }
        default:
            return state
    }
}

export const setIsFetching = (isFetching) => ({
    type: SET_IS_FETCHING, isFetching
})
export const setPaymentUrl = (paymentUrl) => ({
    type: SET_PAYMENT_URL, paymentUrl
})

export const getPaymentUrl = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await checkoutApi.getPaymentUrl(data)
        dispatch([setPaymentUrl(response.paymentUrl), setIsFetching(false)])
    } catch (err) {
        dispatch(setIsFetching(false))
    }
}

export const checkPaymentHash = (paymentHash) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await checkoutApi.checkPaymentHash(paymentHash)
        dispatch(setIsFetching(false))
        return true
    } catch (err) {
        dispatch(setIsFetching(false))
        return false
    }
}

export default commonReducer