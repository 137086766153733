import Container from "../../Components/UI/Container/Container"
import CustomButton from "../../Components/UI/Button/CustomButton"
import Header from "../../Components/UI/Header/Header"
import Text from "../../Components/UI/Text/Text"
import classes from "./Main.module.css"
import Timer from "../../Components/Common/Timer/Timer"
import { cx } from "../../Utils/classnames"
import useWindowDimension from "../../Hooks/useWindowDimension"
import { useState } from "react"
import CommentModal from "../../Components/Modals/CommentModal/CommentModal"
import BuyModal from "../../Components/Modals/BuyModal/BuyModal"

import main_img from "../../Assets/main.png"
import main_img_mob from "../../Assets/homemob.png"
import mom from "../../Assets/mom.jpg"
import emoji from "../../Assets/emoji.svg"
import emoji3 from "../../Assets/emoji3.svg"
import arrow1 from "../../Assets/arrow1.svg"
import videoPreview from "../../Assets/videoPreview.png"
import eyes from "../../Assets/eyes.svg"
import steps from "../../Assets/steps.png"
import steps_mobile from "../../Assets/steps_mobile.png"
import fast_food from "../../Assets/fast-food.svg"
import park from "../../Assets/park.svg"
import car from "../../Assets/car.svg"
import queue from "../../Assets/queue.svg"
import house from "../../Assets/house.svg"
import rec1 from "../../Assets/rec1.png"
import rec2 from "../../Assets/rec2.png"
import rec3 from "../../Assets/rec3.png"
import book from "../../Assets/book.png"

import how1 from "../../Assets/how1.jpg"
import how2 from "../../Assets/how2.jpg"
import how3 from "../../Assets/how3.jpg"
import how4 from "../../Assets/how4.jpg"

import levels from "../../Assets/levels.png"

import who1 from "../../Assets/who1.png"
import who2 from "../../Assets/who2.png"

import rev1 from "../../Assets/rev1.png"
import rev2 from "../../Assets/rev2.png"
import rev3 from "../../Assets/rev3.png"
import rev4 from "../../Assets/rev4.png"
import rev5 from "../../Assets/rev5.png"
import rev6 from "../../Assets/rev6.png"
import rev7 from "../../Assets/rev7.png"

import ma2 from "../../Assets/ma2.jpg"

import logo2 from "../../Assets/logo2.png"
import gift from "../../Assets/gift.svg"

import star from "../../Assets/star.png"

import com1 from "../../Assets/com1.jpg"
import com2 from "../../Assets/com2.jpg"
import com3 from "../../Assets/com3.jpg"
import com4 from "../../Assets/com4.jpg"
import com5 from "../../Assets/com5.jpg"
import com6 from "../../Assets/com6.jpg"
import com7 from "../../Assets/com7.jpg"
import { useRef } from "react"

const comments = [
    {
        who: "Вікторії",
        img: com1
    },
    {
        who: "Оксани",
        img: com2
    },
    {
        who: "Олександри",
        img: com3
    },
    {
        who: "Віталіни",
        img: com4
    },
    {
        who: "Дарини",
        img: com5
    },
    {
        who: "Ольги",
        img: com6
    },
    {
        who: "Марії",
        img: com7
    }
]

const Main = (props) => {
    const [isOpenBuyModal, setIsOpenBuyModal] = useState(false)

    const [comment, setComment] = useState(null)

    const botRef = useRef(null)

    const { width } = useWindowDimension()

    const scrollToBottom = () => {
        botRef.current.scrollIntoView({ behavior: "smooth" })
    }

    const handleOpenCommentModal = (comment) => {
        if (comment !== null) {
            setComment(comments[comment])
        } else {
            setComment(null)
        }
    }

    const handleBuyModal = () => {
        setIsOpenBuyModal(!isOpenBuyModal)
    }

    return (
        <div className={classes.main}>
            {comment !== null && (
                <CommentModal comment={comment} onClose={() => handleOpenCommentModal(null)} />
            )}
            {isOpenBuyModal && <BuyModal onClose={handleBuyModal} />}
            <div className={classes.home}>
                <Container className={classes.homeContainer}>
                    <div className={classes.homeInfo}>
                        <Header type="h1">
                            ДОБІРКА
                            <br />
                            ІНСТА ІГОР
                        </Header>
                        <Text type="p1">
                            для всебічного розвитку дитини до 3-х років
                        </Text>
                        <CustomButton
                            onClick={scrollToBottom}
                            className={classes.buyButt}
                        >
                            Придбати
                        </CustomButton>
                    </div>
                    <img
                        src={width > 1170 ? main_img : main_img_mob}
                        alt="main"
                    />
                </Container>
            </div>
            <div className={classes.about}>
                <Container className={classes.aboutContainer}>
                    <div className={classes.me}>
                        <img
                            src={emoji}
                            alt="emoji"
                            className={classes.emoji}
                        />
                        <img src={mom} alt="Olena" className={classes.mom} />
                        <div className={classes.aboutMeText}>
                            <Text type="p2">
                                Вітаю, мене звуть Олена, я власниця магазину
                                іграшок Острів Дитинства. Я з радістю презентую
                                добірку зі 100 ігор для дітей до 3-х років. 
                            </Text>
                        </div>
                    </div>
                    <div className={classes.aboutInfo}>
                        <Text type="p1">
                            Розкрийте потенціал вашої дитини зі 100 неймовірними
                            іграми від експертів у психології, логопедії та
                            батьків!
                        </Text>
                        <Text type="p2">
                            Наша команда професіоналів створила 100 ігор, які
                            відповідають вимогам для всебічного розвитку вашої
                            дитини.
                        </Text>
                        <Text type="p2">
                            Зокрема, ці ігри сприятимуть розвитку мовлення,
                            асоціативного та логічного мислення та візуального
                            сприйняття навколишнього світу.
                        </Text>
                        <Text type="p2">
                            Наша добірка отримала чудові відгуки від наших
                            клієнтів та успішно пройшла тест-драйв, що
                            підтверджує, що ми зібрали саме ті ігри, які справді
                            ефективні та корисні.
                        </Text>
                        <Text type="p2">
                            <strong>
                                Замовляйте нашу добірку вже сьогодні, щоб
                                допомогти вашій дитині розвиватися на всіх
                                рівнях!
                            </strong>
                        </Text>
                    </div>
                </Container>
            </div>
            {/* <div className={classes.videoSection}>
                <Container className={classes.videoSectionContainer}>
                    <Text type="p1" className={classes.videoTitle}>
                        Дізнайтесь чим буде корисна добірка вам та вашій дитині
                        за <strong>5 хвилин</strong>
                    </Text>
                    <img
                        src={arrow1}
                        alt="arrow"
                        className={classes.videoArrow}
                    />
                    <img
                        src={videoPreview}
                        alt="video_preview"
                        className={classes.videoPreview}
                    />
                    <img src={eyes} alt="eyes" className={classes.eyes} />
                </Container>
            </div> */}
            <div className={classes.how}>
                <Container className={classes.howContainer}>
                    <Header type="h2">Як користуватись добіркою</Header>
                    <div className={classes.howField}>
                        <div className={classes.howBlock}>
                            <img src={how1} alt="how"/>
                            <Text type="p2">Ознайомтесь з добіркою біля дитини, покажіть на своєму прикладі, що вам цікаво</Text>
                        </div>
                        <div className={classes.howBlock}>
                            <img src={how2} alt="how"/>
                            <Text type="p2">Підготуйте іграшки, щоб ви могли візуалізувати ігри в добірці. Вони допоможуть швидше засвоювати матеріал</Text>
                        </div>
                    </div>
                    <div className={classes.howField}>
                        <div className={classes.howBlock}>
                            <img src={how3} alt="how"/>
                            <Text type="p2">Кайфуйте від процесу. Грайте у свій вільний час, без нервів, робочих дзвінків</Text>
                        </div>
                        <div className={classes.howBlock}>
                            <img src={how4} alt="how"/>
                            <Text type="p2">Займайтесь добіркою поступово, з нарощенням складності завдання.</Text>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={classes.dobirka}>
                <Container className={classes.dobirkaContainer}>
                    <Header type="h2">Добірка для розвитку</Header>
                    <img src={width > 568 ? steps : steps_mobile} alt="steps" className={classes.steps} />
                </Container>
            </div>
            <div className={classes.levels}>
                <Container className={classes.levelsContainer}>
                    <Header type="h2">4 рівня складності для довгої гри</Header>
                    <Text type="p2" className={classes.levelsText}>
                        Кожен рівень складності ігор спрямований 
                        на поетапний розвиток дитини, надаючи їй можливість росту та вдосконалення навичок 
                        у веселій та відповідній формі.
                    </Text>
                    <Text type="p2" className={classes.levelsSub}>Розглянемо кожен рівень складності ігор докладніше:</Text>
                    <div className={classes.levelsInfo}>
                        <div className={classes.levelsBlock}>
                            <div className={cx(classes.hard, classes.hard1)}/>
                            <div className={classes.levelsBlockInfo}>
                                <Header type="h3">1-Й РІВЕНЬ</Header>
                                <Text type="p2">
                                    Цей рівень призначений для того, щоб швидше зацікавити дитину добіркою. 
                                    Ігри на цьому рівні можуть бути дуже простими і веселими, спрямованими 
                                    на розвагу та позитивні емоції.
                                </Text>
                            </div>
                        </div>
                        <div className={classes.levelsBlock}>
                            <div className={cx(classes.hard, classes.hard2)}/>
                            <div className={classes.levelsBlockInfo}>
                                <Header type="h3">2-Й РІВЕНЬ</Header>
                                <Text type="p2">
                                    На цьому рівні росте складність завдань. Ви можете додати більше геометричних фігур, кольорів або складніше логічні завдання. Наприклад, дитина може вчити розрізняти та класифікувати різні фігури.
                                </Text>
                            </div>
                        </div>
                        <div className={classes.levelsBlock}>
                            <div className={cx(classes.hard, classes.hard3)}/>
                            <div className={classes.levelsBlockInfo}>
                                <Header type="h3">3-Й РІВЕНЬ</Header>
                                <Text type="p2">
                                    На цьому рівні акцент може бути зроблений на поліпшення логічного мислення та світосприйняття. Ігри можуть включати завдання, де дитина повинна розв'язувати складніші головоломки.
                                </Text>
                            </div>
                        </div>
                        <div className={classes.levelsBlock}>
                            <div className={cx(classes.hard, classes.hard4)}/>
                            <div className={classes.levelsBlockInfo}>
                                <Header type="h3">4-Й РІВЕНЬ</Header>
                                <Text type="p2">
                                    На цьому рівні надається можливість вивчати більш складні концепції, такі як алфавіт, емоції та рахунок. Дитина може вчити літери, цифри та мати можливість взаємодіяти з іграми.
                                </Text>
                            </div>
                        </div>
                    </div>
                    <img src={levels} alt="levels" className={classes.levelsImg}/>
                </Container>
            </div>
            <div className={classes.who}>
                <Container className={classes.whoContainer}>
                    <Header type="h2">Для кого ця добірка</Header>
                    <div className={classes.whoInfo}>
                        <div className={classes.whoBlock}>
                            <img src={who1} alt="who"/>
                            <div className={classes.whoText}>
                                <Header type="h3">Для мам</Header>
                                <Text type="p2">Добірка допоможе зайняти дитину в потрібний момент та швидко вивчати навколишній світ</Text>
                            </div>
                        </div>
                        <div className={classes.whoBlock}>
                            <img src={who2} alt="who"/>
                            <div className={classes.whoText}>
                                <Header type="h3">Для дітей до 3-х років</Header>
                                <Text type="p2">Всебічний розвиток, та правильне користування телефоном гарантоване</Text>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={classes.place}>
                <Container className={classes.placeContainer}>
                    <Header type="h2">Місця використання добірки</Header>
                    <div className={classes.placeWrapper}>
                        <div className={classes.placeItem}>
                            <img src={fast_food} alt="place" />
                            <Text type="p2">У ресторані</Text>
                        </div>
                        <div className={classes.placeItem}>
                            <img src={park} alt="place" />
                            <Text type="p2">На вулиці</Text>
                        </div>
                        <div className={classes.placeItem}>
                            <img src={car} alt="place" />
                            <Text type="p2">Під час подорожі в машині</Text>
                        </div>
                        <div className={classes.placeItem}>
                            <img src={queue} alt="place" />
                            <Text type="p2">У черзі</Text>
                        </div>
                        <div className={classes.placeItem}>
                            <img src={house} alt="place" />
                            <Text type="p2">Вдома</Text>
                        </div>
                    </div>
                </Container>
            </div>
            {/* <div className={classes.recomendations}>
                <Container className={classes.recomendationsContainer}>
                    <Header type="h2">
                        Рекомендації від
                        <br />
                        експертів по добірці
                    </Header>
                    <div className={classes.recWrapper}>
                        <div className={classes.rec}>
                            <img src={rec1} alt="blogger" />
                            <CustomButton>dr.iryna</CustomButton>
                        </div>
                        <div className={classes.rec}>
                            <img src={rec2} alt="blogger" />
                            <CustomButton>logoclub.logoped</CustomButton>
                        </div>
                        <div className={classes.rec}>
                            <img src={rec3} alt="blogger" />
                            <CustomButton>logoped.ternopil</CustomButton>
                        </div>
                    </div>
                </Container>
            </div> */}
            <Header type="h2" className={classes.reviewHeader}>
                Відгуки про добірку
            </Header>
            <div className={classes.reviews}>
                <Container className={classes.reviewsContainer}>
                    <div className={cx(classes.review, classes.rev1)}>
                        <div
                            className={classes.reviewImgContainer}
                            onClick={() => handleOpenCommentModal(0)}
                        >
                            <span>+</span>
                            <img src={rev1} alt="rev" />
                        </div>
                        <Text type="p2">Вікторія</Text>
                    </div>
                    <div className={cx(classes.review, classes.rev2)}>
                        <div
                            className={classes.reviewImgContainer}
                            onClick={() => handleOpenCommentModal(1)}
                        >
                            <span>+</span>
                            <img src={rev2} alt="rev" />
                        </div>
                        <Text type="p2">Оксана</Text>
                    </div>
                    <div className={cx(classes.review, classes.rev3)}>
                        <div
                            className={classes.reviewImgContainer}
                            onClick={() => handleOpenCommentModal(2)}
                        >
                            <span>+</span>
                            <img src={rev3} alt="rev" />
                        </div>
                        <Text type="p2">Олександра</Text>
                    </div>
                    <div
                        className={cx(classes.review, classes.rev4)}
                        onClick={() => handleOpenCommentModal(3)}
                    >
                        <div className={classes.reviewImgContainer}>
                            <span>+</span>
                            <img src={rev4} alt="rev" />
                        </div>
                        <Text type="p2">Віталіна</Text>
                    </div>
                    <div
                        className={cx(classes.review, classes.rev5)}
                        onClick={() => handleOpenCommentModal(4)}
                    >
                        <div className={classes.reviewImgContainer}>
                            <span>+</span>
                            <img src={rev5} alt="rev" />
                        </div>
                        <Text type="p2">Дарина</Text>
                    </div>
                    <div
                        className={cx(classes.review, classes.rev6)}
                        onClick={() => handleOpenCommentModal(5)}
                    >
                        <div className={classes.reviewImgContainer}>
                            <span>+</span>
                            <img src={rev6} alt="rev" />
                        </div>
                        <Text type="p2">Ольга</Text>
                    </div>
                    <div
                        className={cx(classes.review, classes.rev7)}
                        onClick={() => handleOpenCommentModal(6)}
                    >
                        <div className={classes.reviewImgContainer}>
                            <span>+</span>
                            <img src={rev7} alt="rev" />
                        </div>
                        <Text type="p2">Марія</Text>
                    </div>
                </Container>
            </div>
            <div className={classes.features}>
                <Container className={classes.featuresContainer}>
                    <Header type="h2">При покупці добірки ви потрапляєте в наше <span>закрите ком’юніті</span>, у якому отримаєте:</Header>
                    <div className={classes.featuresField}>
                        <div className={classes.featuresPoints}>
                            <div className={classes.feature}>
                                <img src={star} alt="star"/>
                                <Text type="p2">Підборку відео від логопедів, як комбінувати добірку з іграшками для 3D візуалізації</Text>
                            </div>
                            <div className={classes.feature}>
                                <img src={star} alt="star"/>
                                <Text type="p2">Підтримку від мам однодумців</Text>
                            </div>
                            <div className={classes.feature}>
                                <img src={star} alt="star"/>
                                <Text type="p2">Можливість купувати іграшки по оптовій ціні в майбутньому </Text>
                            </div>
                            <div className={classes.feature}>
                                <img src={star} alt="star"/>
                                <Text type="p2">Прямі ефіри з логопедами, педіатрами та іншими спеціалістами</Text>
                            </div>
                            <div className={classes.feature}>
                                <img src={star} alt="star"/>
                                <Text type="p2">Знижки до -50% відсотків на навчальні продукти від Острів Дитинства</Text>
                            </div>
                            <div className={classes.feature}>
                                <img src={star} alt="star"/>
                                <Text type="p2"> Розбір іграшок від спеціалістів</Text>
                            </div>
                        </div>
                        <div className={classes.featuresImg}>
                            <img src={eyes} alt="eyes" className={classes.featureEye}/>
                            <img src={ma2} alt="mama" className={classes.featureMa}/>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={classes.buy} ref={botRef}>
                <Container className={classes.buyContainer}>
                    <div className={classes.buyLeft}>
                        <Header type="h2">
                            Вартість добірки зі 100 іграми
                        </Header>
                        <div className={classes.price}>
                            <p className={classes.oldPrice}>580 грн</p>
                            <p className={classes.newPrice}>380 грн</p>
                        </div>
                        <CustomButton
                            onClick={handleBuyModal}
                            className={classes.buyButt}
                        >
                            Придбати
                        </CustomButton>
                        <Timer />
                    </div>
                    <div className={classes.buyRight}>
                        <Text type="p1" className={classes.buyRightTitle}>
                            <img src={gift} alt="gift" className={classes.gift}/>
                            Додтаково в подарунок ви ще отримаєте добірку з 25
                            ігор
                        </Text>
                        <Text type="p2">
                            Це добірка з цікавих ігор без іграшок на будь-який
                            вік та на кожен день
                        </Text>
                        <img src={book} alt="book" className={classes.book}/>
                    </div>
                </Container>
            </div>
            <div className={classes.footer}>
                <div className={classes.footerContainer}>
                    <Header type="h2" className={classes.footerTitle}>
                        Побажання від команди
                        <img src={emoji3} alt="emoji" />
                    </Header>
                    <Text type="p2" className={classes.footText}>
                        Команда Острівка Дитинства бажає успішної реалізації
                        добірки ігор, та задоволенної дитини. І пам’ятайте, діти
                        - наше з вами майбутнє, не зволікайте всебічному
                        розвитку з маленького віку.
                    </Text>
                    <a
                        href="https://ostrivdytynstva.com"
                        rel="noreferrer"
                        target={"_blank"}
                    >
                        <img src={logo2} alt="logo" className={classes.footLogo} />
                    </a>
                    <div className={classes.rules}>
                        <a
                            href="https://ostrivdytynstva.com/rules"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            Правила користування сайтом
                        </a>
                        <a
                            href="https://ostrivdytynstva.com/confidentiality"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            Політика конфіденційності
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main
