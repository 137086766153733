import Header from "../../UI/Header/Header"
import Modal from "../../UI/Modal/Modal"
import classes from "./BuyModal.module.css"
import { Controller, useForm } from "react-hook-form"
import CustomButton from "../../UI/Button/CustomButton"
import CustomInput from "../../UI/Form/Input"
import { connect } from "react-redux"
import { getPaymentUrl } from "../../../Redux/commonReducer"
import { useEffect } from "react"

const BuyModal = (props) => {
    const { onClose, getPaymentUrl, paymentUrl } = props

    const { handleSubmit, reset, control } = useForm()

    const onSubmit = (data) => {
        const info = {
            receiver_info: {
                ...data
            },
            finaly_sum: 380
        }
        getPaymentUrl(info)
    }

    useEffect(() => {
        if (paymentUrl) {
            window.location.replace(paymentUrl);
        }
    }, [paymentUrl])

    return (
        <Modal onClose={onClose}>
            <div className={classes.main}>
                <Header type="h2">Напишіть свої контактні данні</Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Обов'язкове поле!" }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <CustomInput
                                onChange={onChange}
                                value={value}
                                error={error}
                                placeholder="Ім'я"
                            />
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "Обов'язкове поле!",
                            pattern: {
                                value:  /^[0-9]\d{9}$/,
                                message: "Некоректний номер телефону!"
                            }
                        }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <CustomInput
                                onChange={onChange}
                                value={value}
                                regex={"number"}
                                error={error}
                                placeholder="Номер телефону"
                                startAdornment={true}
                                startAdornmentIcon={"+38"}
                            />
                        )}
                    />
                    <CustomButton type="submit">Придбати</CustomButton>
                </form>
            </div>
        </Modal>
    )
}

let mapStateToProps = (state) => ({
    paymentUrl: state.common.paymentUrl
})

export default connect(mapStateToProps, {
    getPaymentUrl
})(BuyModal)
