import Button from "@mui/material/Button"
import { cx } from "../../../Utils/classnames"
import classes from "./CustomButton.module.css"

const CustomButton = (props) => {
    const { children, className, onClick, type = "button" } = props

    return (
        <Button onClick={onClick} className={cx(classes.main, className)} type={type}>
            {children}
        </Button>
    )
}

export default CustomButton