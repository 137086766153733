import { useEffect } from "react";
import { cx } from "../../../Utils/classnames"
import classes from "./OverflowLayout.module.css"

import Aos from 'aos';
import 'aos/dist/aos.css';

const OverflowLayout = (props) => {
    const { children, className } = props

    useEffect(() => {
        Aos.init({duration: 1000})
    }, [])

    return (
        <div className={cx(classes.main, className)} data-aos="fade" data-aos-duration="200">
            {children}
        </div>
    )
}

export default OverflowLayout