import Header from "../../UI/Header/Header"
import Modal from "../../UI/Modal/Modal"
import Text from "../../UI/Text/Text"
import classes from "./CommentModal.module.css"

const CommentModal = (props) => {
    const { comment, onClose } = props

    const handleClose = () => {
        onClose(null)
    }

    return (
        <Modal onClose={handleClose}>
            <div className={classes.main}>
                <Header type="h2">ВІДГУК {comment.who}</Header>
                <div className={classes.body}>
                    <img src={comment.img}/>
                </div>
            </div>
        </Modal>
    )
}

export default CommentModal