import { cx } from "../../../Utils/classnames"
import classes from "./Text.module.css"

const Text = (props) => {
    const { children, className, type } = props

    return (
        <>
            {type === "p1" && (
                <p className={cx(classes.p1, className)}>{children}</p>
            )}
            {type === "p2" && (
                <p className={cx(classes.p2, className)}>{children}</p>
            )}
        </>
    )
}

export default Text