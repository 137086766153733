import "./App.css"
import Main from "./Pages/Main/Main"
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import ThankyouContainer from "./Pages/Thankyou/ThankyouContainer";

const App = (props) => {
    return (
        <div className="main">
            <Navbar/>
            <Router>
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/thankyou/:paymentHash" element={<ThankyouContainer/>}/>
                </Routes>
            </Router>
        </div>
    )
}

export default App
