import Container from "../../Components/UI/Container/Container"
import Header from "../../Components/UI/Header/Header"
import Text from "../../Components/UI/Text/Text"
import classes from "./Thankyou.module.css"
import heart from "../../Assets/heart.svg"
import final_book from "../../Assets/final_book.png"
import CustomButton from "../../Components/UI/Button/CustomButton"

const Thankyou = (props) => {
    const { onClick } = props

    return (
        <div className={classes.main}>
            <Container className={classes.container}>
                <img src={heart} alt="like" className={classes.likeMobile}/>
                <div className={classes.info}>
                    <Header type="h2">Дякуємо, що зробили оплату!</Header>
                    <div className={classes.text}>
                        <Text type="p1">
                            Забирайте добірку в нашому телеграм боті
                        </Text>
                        <img src={heart} alt="like" className={classes.like}/>
                    </div>
                    <CustomButton onClick={onClick}>Забрати</CustomButton>
                </div>
                <img src={final_book} alt="book" className={classes.book}/>
            </Container>
        </div>
    )
}

export default Thankyou