import Thankyou from "./Thankyou"
import { useNavigate, useParams } from 'react-router-dom';
import { checkPaymentHash } from "../../Redux/commonReducer";
import { connect } from "react-redux";
import { useEffect } from "react";

const ThankyouContainer = (props) => {
    const { checkPaymentHash  } = props

    const { paymentHash } = useParams()
    const navigate = useNavigate()

    const onClick = () => {
        window.open("https://t.me/ostriv_dytynstva_bot");
    }
    
    useEffect(() => {
        if (!paymentHash) {
            navigate("/")
        } else {
            checkPaymentHash(paymentHash).then(response => {
                if (!response) {
                    navigate("/")
                }
            })
        }
    }, [paymentHash])

    return (
        <Thankyou onClick={onClick}/>
    )
}

export default connect(null, {
    checkPaymentHash
})(ThankyouContainer)