import classes from "./Timer.module.css"
import emoji2 from "../../../Assets/emoji2.svg"
import Text from "../../UI/Text/Text"
import { useState } from "react";
import { useEffect } from "react";

const Timer = () => {
    const [timer, setTimer] = useState({ hours: 12, minutes: 35 });

    useEffect(() => {
        const interval = setInterval(() => {
          setTimer(prevTimer => {
            let newMinutes = prevTimer.minutes - 1;
            let newHours = prevTimer.hours;
    
            if (newMinutes < 0) {
                newMinutes = 59;
                newHours -= 1;
        
                if (newHours < 0) {
                    clearInterval(interval);
                    return { hours: 0, minutes: 0 };
                }
            }
    
            return { hours: newHours, minutes: newMinutes };
          });
        }, 60000);
    
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={classes.main}>
            <img src={emoji2} alt="emoji" className={classes.emoji}/>
            <Text type="p1">До кінця акції залишилось</Text>
            <div className={classes.time}>
                <div className={classes.count}>
                    <Text type="p2">годин</Text>
                    <span>{timer.hours}</span>
                </div>
                <div className={classes.bullets}>:</div>
                <div className={classes.count}>
                    <Text type="p2">хвилин</Text>
                    <span>{timer.minutes}</span>
                </div>
            </div>
        </div>
    )
}

export default Timer