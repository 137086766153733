import axios from "axios";

// export const host = 'http://localhost:3002'
export const host = 'https://api.ostrivdytynstva.com'

export const baseURL = `${host}/api`;

const instance = axios.create({
    baseURL: baseURL
});

export const checkoutApi = {
    getPaymentUrl(data) {
        return instance.post(`/order/test`, data)
        .then(response => response.data)
    },
    checkPaymentHash(paymentHash) {
        return instance.get(`/order/checkPayment/${paymentHash}`)
        .then(response => response.data)
    }
}