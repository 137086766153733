import classes from "./Navbar.module.css"
import logo from "../../Assets/logo.png"
import Container from "../UI/Container/Container"

const Navbar = (props) => {
    return (
        <div className={classes.main}>
            <Container className={classes.container}>
                <a rel="noreferrer" href="https://ostrivdytynstva.com" target={"_blank"}>
                    <img src={logo} alt="Logo"/>
                </a>
            </Container>
        </div>
    )
}

export default Navbar