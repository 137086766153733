import { useEffect } from "react";
import IconButton from "@mui/material/IconButton"
import OverflowLayout from "../OverflowLayout/OverflowLayout"
import { AiOutlineClose } from "react-icons/ai"
import classes from "./Modal.module.css"

import Aos from 'aos';
import 'aos/dist/aos.css';

const Modal = (props) => {
    const { onClose, children } = props

    useEffect(() => {
        Aos.init({duration: 1000})
    }, [])

    return (
        <OverflowLayout>
            <div className={classes.window} data-aos="zoom-in" data-aos-duration="300">
                <div className={classes.header}>
                    <IconButton onClick={onClose}>
                        <AiOutlineClose/>
                    </IconButton>
                </div>
                <div className={classes.body}>
                    {children}
                </div>
            </div>
        </OverflowLayout>
    )
}

export default Modal